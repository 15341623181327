import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"

import "./header.scss"

const Header = ({ isIframe, indexPage }) => {
  const BrandTag = indexPage ? "h1" : "div"
  const [showNav, toggleNav] = useState(false)
  return (
    <header className={isIframe ? "site-header is-iframe" : "site-header"}>
      <BrandTag className="site-brand" style={{ margin: 0 }}>
        <Link to="/">All Newspapers</Link>
      </BrandTag>
      <nav className="site-nav">
        <button
          className="mobile-toggle"
          onClick={e => {
            e.preventDefault()

            toggleNav(!showNav)
          }}
        >
          <span />
          <span />
          <span />
        </button>
        <ul className={showNav ? "show-nav" : ""}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/bangla-newspapers">Bangla Newspapers</Link>
          </li>
          <li>
            <Link to="/english-newspapers">English Newspapers</Link>
          </li>
          <li>
            <Link to="/international-newspapers">International Newspapers</Link>
          </li>
          {/* <li>
            <Link to="/tv-channels">TV Channels</Link>
          </li> */}
          {/* <li>
            <Link to="/contact">Contact Us</Link>
          </li> */}
        </ul>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
