/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./bootstrap-reboot.min.css"
import "./layout.scss"

const Layout = ({ children, indexPage, isIframe }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        isIframe={isIframe}
        indexPage={indexPage}
      />
      {isIframe ? (
        <>{children}</>
      ) : (
        <>
          <div className="page-content">
            <div
              className="container"
              style={{
                marginBottom: "50px",
              }}
            >
              {children}
            </div>
          </div>
          <footer className="site-footer">
            <div className="copyright">
              © {new Date().getFullYear()} Copyright{" "}
              <a href="https://allnewspapers.xyz">allnewspapers.xyz</a> | All
              Rights Reserved.
            </div>
          </footer>
        </>
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
